<template>
  <div>
    <div class="star-ratings" v-if="ratings > 0">
      <i
        class="el-icon-star-on star-ratings-item"
        v-for="n in Math.round(ratings)" :key="'start' + n" />
    </div>
    <div class="star-ratings" v-else>
      <i
        class="el-icon-star-off star-ratings-item-off"
        v-for="n in 5" :key="'start' + n" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StarRatings',
  props: {
    ratings: {
      type: Number,
      required: true,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .star-ratings-item {
    color: $star-rating-color !important;
  }
  .star-ratings-item-off {
    opacity: 0.3;
  }
</style>
