<template>
  <PpModal v-model="open">
    <PpCard class="box has-border is-flex">
      <div class="send-email-modal-content">
        <el-form
          v-if="!isFaxSent"
          ref="sendFaxForm"
          :model="sendFaxForm"
          :rules="rules"
          label-position="top"
          status-icon
        >
          <div>
            <h3 class="is-size-4 has-text-secondary has-text-weight-bold">Send credit card info to hotel</h3>
          </div>
          <el-alert
            type="info"
            show-icon
            :closable="false">
            <template slot="title">
              Introduce the Fax number including: Country code + City code + Fax number without any space or symbol, use only digits.<br>
              <strong>E.g. 33143220911, where 33 (country code), 1 (city code) and the rest is the Fax number.</strong>
              <br>To find country and city codes around the world, use this link as reference: <a href="https://countrycode.org" target="_blank">https://countrycode.org</a>
            </template>
          </el-alert>
          <el-form-item
            label="Fax Number"
            prop="faxNumber"
            class="contact-person--contact-no"
          >
            <el-input type="tel" placeholder="Eg:1234xxxxx"
                name="faxNumber"
                autocomplete="tel-national"
                v-model="sendFaxForm.faxNumber"
                @change="handleBlur">
            </el-input>
          </el-form-item>
          <el-form-item v-if="checkPermission(['SAVE_FAX_NUMBER'])">
            <el-checkbox v-model="sendFaxForm.overrideFax">Save to hotel fax number</el-checkbox>
            <el-alert
              v-if="sendFaxForm.overrideFax"
              title="This will override default fax number in the future use"
              type="warning"
              show-icon
              :closable="false">
            </el-alert>
          </el-form-item>
          <div class="has-text-right">
            <el-button
              :disabled="isFetching"
              style="min-width: 115px;"
              @click="handleCancel">
              Cancel
            </el-button>
            <el-button
              type="primary"
              :loading="isFetching"
              style="min-width: 125px;"
              native-type="button"
              @click="handleSendFax">
              Send
            </el-button>
          </div>
        </el-form>
        <div
          v-else
          class="">
          <div class="">
            <h3 class="is-size-4 has-text-secondary has-text-weight-bold">Fax sent</h3>
          </div>
          <div class="">
            <p>The credit card info has been sent to <b>{{ localForm.faxNumber }}</b></p>
          </div>
          <div class="column is-12 has-text-right">
            <el-button
              type="primary"
              style="min-width: 125px;"
              @click="handleClose">
              OK
            </el-button>
          </div>
        </div>
      </div>
    </PpCard>
  </PpModal>
</template>

<script>
import lv2, { axiosErrorHandler } from '../../factories/lv2Api'
import checkPermission from '@/plugins/permission'

const asyncPostResendFax = async (payload) => {
  try {
    const { data } = await lv2.post('/orders/resend-fax', payload)
    // eslint-disable-next-line no-unused-vars
    const { outlets, meta } = data
    return outlets
  } catch (err) {
    const parseErr = axiosErrorHandler(err)
    throw parseErr
  }
}

export default {
  name: 'SendFaxModal',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    orderReference: {
      type: String,
      default: ''
    },
    order: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sendFaxForm: {
        faxNumber: '',
        overrideFax: false
      },
      localForm: {
        faxNumber: ''
      },
      isFaxSent: false,
      rules: {
        faxNumber: [
          {
            type: 'string',
            message: 'Invalid format. Please follow the guideline above',
            trigger: ['change', 'blur'],
            pattern: /^[1-9]\d{1,20}$/ig
          }, {
            required: true,
            message: 'Fax number is required',
            trigger: ['change', 'blur']
          }
        ]
      },
      isFetching: false
    }
  },
  watch: {
    order (nV) {
      this.sendFaxForm.faxNumber = this.property && this.property.contacts && this.property.contacts.fax && this.property.contacts.fax.replace(/-/g, '').replace(/\s/g, '')
    }
  },
  methods: {
    checkPermission,
    validateForm () {
      return new Promise((resolve, reject) => {
        this.$refs.sendFaxForm.validate((valid, fields) => {
          resolve({
            valid,
            fields
          })
        })
      })
    },
    handleSendFax (e) {
      this.validateForm()
      e.preventDefault()
      this.$refs.sendFaxForm.validate(async (valid) => {
        if (valid) {
          this.isFetching = true
          asyncPostResendFax({
            orderReference: this.orderReference,
            appCode: this.order.appCode,
            globalPreview: this.order.previewMode,
            faxNumber: this.sendFaxForm.faxNumber,
            overrideFax: this.sendFaxForm.overrideFax
          }).then(res => {
            this.localForm.faxNumber = this.sendFaxForm.faxNumber
            this.isFetching = false
            this.isFaxSent = true
            setTimeout(() => {
              this.$emit('sent')
            }, 5000)
          }).catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            })
            this.isFetching = false
            this.isFaxSent = false
          })
          return true
        } else {
          return false
        }
      })
    },
    handleClose () {
      this.isFaxSent = false
      this.$emit('update:open', false)
    },
    handleCancel () {
      this.isFaxSent = false
      this.$refs.sendFaxForm.resetFields()
      this.handleClose()
    },
    handleBlur () {
      this.$emit('input', { ...this.sendFaxForm })
    }
  }
}
</script>

<style scoped>

</style>
