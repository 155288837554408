/**
 * Master list of available column options for order table.
 * It share among fornt-end (vue) and backend (node|typescript).
 */

const cols = {
  adultCount: {
    label: 'Adults',
    description: 'Number of adults',
    /**
     * Role level, has to passed this level to view this col
     */
    level: 0,
    /**
     * Select cols will be join into sql query
     */
    select: [
      '"booking"."adultCount" as "booking.adultCount"'
    ],
    /**
     * Available sorting options
     */
    sortOptions: {
      adultCount: {
        col: '"booking"."adultCount"',
        label: 'Adults'
      }
    },
    /**
     * Colmns to show when generating Csv
     */
    reportColumns: [
      {
        col: 'booking.adultCount',
        label: 'Adults',
        align: 'right',
        type: 'number',
        width: 40
      }
    ],
    /**
     * To consume with Vue.js
     */
    render () {
      /**
       * Must return VNode configurations.
       */
      return {
        props: {
          align: 'right',
          minWidth: '90',
          prop: '"booking"."adultCount"',
          label: 'Adults',
          sortable: 'custom',
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => this.h('span', row.booking.adultCount)
        },
        key: 'adultCount'
      }
    }
  },
  // affiliateCode: {
  //   label: 'Affiliate Code',
  //   description: 'Affiliate tracking code',
  //   level: 0,
  //   select: [
  //     '"order"."affiliateCode" as "order.affiliateCode"'
  //   ],
  //   sortOptions: {
  //     affiliateCode: {
  //       col: '"order"."affiliateCode"',
  //       label: 'Affiliate code',
  //     },
  //   },
  //   reportColumns: [
  //     {
  //       col: 'order.affiliateCode',
  //       label: 'Affiliate code',
  //       align: 'left',
  //       width: 120,
  //     }
  //   ],
  //   render () {
  //     return {
  //       props: {
  //         align: 'left',
  //         minWidth: '140',
  //         prop: '"order"."affiliateCode"',
  //         label: 'Affiliate code',
  //         sortable: 'custom',
  //       },
  //       scopedSlots: {
  //         default: ({ row }) => ([
  //           this.h('span', row.order.affiliateCode || 'n.a')
  //         ]),
  //       },
  //       key: 'affiliateCode',
  //     };
  //   },
  // },
  // affiliateCommission: {
  //   label: 'Affiliate commission',
  //   description: 'Percentage of profit',
  //   level: 0,
  //   select: [
  //     '"order"."affiliateCommissionPct" as "order.affiliateCommissionPct"'
  //   ],
  //   sortOptions: {
  //     affiliateCommission: {
  //       col: '"order"."affiliateCommissionPct"',
  //       label: 'Affiliate commission',
  //     },
  //   },
  //   reportColumns: [
  //     {
  //       col: 'order.affiliateCommissionPct',
  //       label: 'Affiliate commission',
  //       align: 'right',
  //       type: 'number',
  //       width: 180,
  //     },
  //   ],
  //   render () {
  //     return {
  //       props: {
  //         align: 'right',
  //         minWidth: '180',
  //         prop: '"order"."affiliateCommissionPct"',
  //         label: 'Affiliate commission',
  //         sortable: 'custom',
  //         showOverflowTooltip: true,
  //       },
  //       scopedSlots: {
  //         default: ({ row }) => ([
  //           this.h('span', row.order.affiliateCommissionPct ? this.filters.pct(row.order.affiliateCommissionPct) : 0),
  //         ]),
  //       },
  //       key: 'affiliateCommission',
  //     };
  //   },
  // },
  appCode: {
    label: 'App code',
    description: 'Application code',
    /**
     * Role level, has to passed this level to view this col
     */
    level: 0,
    /**
     * Select cols will be join into sql query
     */
    select: [
      '"order"."appCode" as "order.appCode"'
    ],
    /**
     * Available sorting options
     */
    sortOptions: {
      requestedAt: {
        col: '"order"."appCode" ',
        label: 'App code'
      }
    },
    /**
     * Colmns to show when generating Csv
     */
    reportColumns: [
      {
        col: 'order.appCode',
        label: 'App code',
        align: 'center',
        width: 80
      }
    ],
    /**
     * To consume with Vue.js
     */
    render () {
      /**
       * Must return VNode configurations.
       */
      return {
        props: {
          align: 'left',
          width: '170',
          prop: '"order"."appCode"',
          label: 'App code',
          sortable: 'custom',
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => this.h('span', row.order.appCode)
          /**
           * Header scope-slot, refer to vue doc
           */
          // header: ({ column }) => ([
          //   column.label,
          //   ' ',
          //   this.h('el-tooltip', {
          //     props: {
          //       placement: 'top',
          //       content: 'Date is display in your timezone',
          //     },
          //   }, [this.h('i', { class: { 'el-icon-question': true } })]),
          // ]),
        },
        key: 'appCode'
      }
    }
  },
  children: {
    label: 'Children',
    description: 'Number of children and their ages',
    level: 0,
    select: [
      '"booking"."childrenCount" as "booking.childrenCount"',
      '"booking"."childrenAges" as "booking.childrenAges"'
    ],
    sortOptions: {
      children: {
        col: '"booking"."childrenCount"',
        label: 'Children'
      }
    },
    /**
     * Colmns to show when generating Csv
     */
    reportColumns: [
      {
        col: '"booking"."childrenCount"',
        label: 'Children',
        align: 'right',
        type: 'number',
        width: 40
      },
      {
        col: '"booking"."childrenAges"',
        label: 'Children\'s ages',
        align: 'left'
      }
    ],
    /**
     * To consume with Vue.js
     */
    render () {
      /**
       * Must return VNode configurations.
       */
      return {
        props: {
          align: 'right',
          minWidth: '100',
          prop: '"booking"."childrenCount"',
          label: 'Children',
          sortable: 'custom',
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', row.booking.childrenCount || 0),
            this.h('p', { class: 'sub-text' }, row.booking.childrenAges ? `Ages: ${row.booking.childrenAges.join(', ')}` : '')
          ])
        },
        key: 'children'
      }
    }
  },
  contactPerson: {
    label: 'Contact person',
    description: 'Person who made this the booking',
    level: 0,
    select: [
      '"contactPerson"."fullName" as "contactPerson.fullName"',
      '"contactPerson"."email" as "contactPerson.email"'
    ],
    sortOptions: {
      contactPerson: {
        col: '"contactPerson"."fullName"',
        label: 'Contact person'
      },
      contactPersonEmail: {
        col: '"contactPerson"."email"',
        label: 'Contact person\'s email'
      }
    },
    reportColumns: [
      {
        col: 'contactPerson.fullName',
        label: 'Contact person',
        align: 'left',
        width: 180
      },
      {
        col: 'contactPerson.email',
        label: 'Contact person\'s email',
        align: 'left',
        width: 220
      }
    ],
    render () {
      return {
        props: {
          align: 'left',
          minWidth: '180',
          prop: '"contactPerson"."fullName"',
          label: 'Contact person',
          sortable: 'custom'
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', row.contactPerson.fullName),
            this.h('p', { class: 'sub-text' }, row.contactPerson.email)
          ])
        },
        key: 'contactPerson'
      }
    }
  },
  cost: {
    label: 'Cost',
    description: 'Cost',
    level: 0,
    select: [
      '"order"."cost" as "order.cost.val"',
      '"order"."costCcy" as "order.cost.ccy"'
    ],
    sortOptions: {
      cost: {
        col: '"order"."cost"',
        label: 'Cost'
      }
    },
    reportColumns: [
      {
        col: 'order.cost.val',
        label: 'Cost',
        align: 'right',
        type: 'number',
        width: 130
      },
      {
        col: 'order.cost.ccy',
        label: 'Cost CCY',
        align: 'center',
        width: 80
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '120',
          prop: '"order"."cost"',
          label: 'Cost',
          resizable: false,
          sortable: 'custom'
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', { class: 'semibold' }, this.filters.ccyObject(row.order.cost)),
            this.h('p', { class: 'sub-text' }, row.order.cost ? row.order.cost.ccy : '')
          ])
        },
        key: 'cost'
      }
    }
  },
  initialSellRate: {
    label: 'Initial Sell Rate',
    description: 'Initial Sell Rate',
    level: 0,
    select: [
      '"order"."initialSellRate" as "order.initialSellRate.val"',
      '"order"."initialSellRateCcy" as "order.initialSellRate.ccy"'
    ],
    sortOptions: {
      cost: {
        col: '"order"."initialSellRate"',
        label: 'Cost'
      }
    },
    reportColumns: [
      {
        col: 'order.initialSellRate.val',
        label: 'Cost',
        align: 'right',
        type: 'number',
        width: 130
      },
      {
        col: 'order.initialSellRate.ccy',
        label: 'Cost CCY',
        align: 'center',
        width: 80
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '150',
          prop: '"order"."initialSellRate"',
          label: 'Initial Sell Rate',
          resizable: false,
          sortable: 'custom'
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', { class: 'semibold' }, this.filters.ccyObject(row.order.initialSellRate)),
            this.h('p', { class: 'sub-text' }, row.order.initialSellRate ? row.order.initialSellRate.ccy : '')
          ]),
          header: ({ column }) => ([
            column.label,
            ' ',
            this.h('el-tooltip', {
              props: {
                placement: 'top',
                content: 'Base Selling Price'
              }
            }, [this.h('i', { class: { 'el-icon-question': true } })])
          ])
        },
        key: 'initialSellRate'
      }
    }
  },
  initialForex: {
    label: 'Initial Forex',
    description: 'Initial Forex',
    level: 0,
    select: [
      '"order"."initialForex" as "order.initialForex"'
    ],
    sortOptions: {},
    reportColumns: [
      {
        col: 'order.initialForex',
        label: 'Initial Forex',
        align: 'center'
      }
    ],
    render () {
      return {
        props: {
          align: 'center',
          width: '160',
          prop: 'order.initialForex',
          label: 'Initial Forex',
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', { class: 'semibold' }, row.order.initialForex)
          ]),
          header: ({ column }) => ([
            column.label,
            ' ',
            this.h('el-tooltip', {
              props: {
                placement: 'top',
                content: 'From openexchangerates.org (Included forex markup)'
              }
            }, [this.h('i', { class: { 'el-icon-question': true } })])
          ])
        },
        key: 'initialForex'
      }
    }
  },
  orderStatus: {
    label: 'Order status',
    description: 'State of order',
    level: 0,
    select: [
      '"order"."status" as "order.status"'
    ],
    sortOptions: {
      orderStatus: {
        col: '"order"."status"',
        label: 'Order status'
      }
    },
    reportColumns: [
      {
        col: 'order.status',
        label: 'Order status',
        align: 'center'
      }
    ],
    render () {
      return {
        props: {
          align: 'center',
          minWidth: '160',
          prop: 'order.status.label',
          label: 'Status',
          // sortable: 'custom',
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => this.h('div', {
            class: ['card-booking-status', row.order.status.tone]
          }, row.order.status.label)
        },
        key: 'orderStatus'
      }
    }
  },
  failureReason: {
    label: 'Failure Reason',
    description: 'Reason for the failure',
    level: 0,
    render () {
      return {
        props: {
          align: 'center',
          minWidth: '160',
          prop: 'order.failureReason',
          label: 'Failure Reason',
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => this.h('div', {}, row.order.failureReason)
        },
        key: 'failureReason'
      }
    }
  },
  rateType: {
    label: 'Payment type',
    description: 'Pay at hotel or net',
    level: 0,
    select: [
      '"payment"."paymentGateway" as "payment.paymentGateway"'
    ],
    reportColumns: [
      {
        col: 'payment.paymentGateway',
        label: 'Payment Type',
        align: 'center'
      }
    ],
    render () {
      return {
        props: {
          align: 'center',
          minWidth: '120',
          prop: 'payment.status.label',
          label: 'Payment Type',
          // sortable: 'custom',
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => this.h('span', {}, `${row.payment.paymentGateway === 'pay_at_hotel' ? 'pay_at_hotel' : 'net'}`)
        },
        key: 'rateType'
      }
    }
  },
  property: {
    label: 'Property',
    description: 'Name of the accomandation',
    level: 0,
    select: [
      '"booking"."propertyName" as "booking.propertyName"'
    ],
    sortOptions: {
      propertyName: {
        col: '"booking"."propertyName"',
        label: 'Property name'
      },
      propertyCity: {
        col: 'property.location.city',
        label: 'Property city'
      },
      propertyState: {
        col: 'property.location.stateProvince',
        label: 'Property State/Province'
      },
      propertyCountryCode: {
        col: 'property.location.countryCode',
        label: 'Property country code'
      }
    },
    reportColumns: [
      {
        col: 'booking.propertyName',
        label: 'Property name',
        align: 'left',
        width: 320
      },
      {
        col: 'property.location.city',
        label: 'Property city',
        align: 'left',
        width: 200
      },
      {
        col: 'property.location.stateProvince',
        label: 'Property state/province',
        align: 'left',
        width: 40
      },
      {
        col: 'property.location.countryCode',
        label: 'Property country code',
        align: 'left',
        width: 40
      }
    ],
    render () {
      return {
        props: {
          align: 'left',
          minWidth: '200',
          prop: '"booking"."propertyName"',
          label: 'Property',
          sortable: 'custom',
          showOverflowTooltip: true
        },
        scopedSlots: {
          default: ({ row }) => ([
            row.booking.propertyName,
            this.h('p', { class: 'sub-text' }, `${row.property && row.property.location.city}${row.property && row.property.location.stateProvince && (row.property.location.stateProvince !== null || row.property.location.stateProvince !== '') ? ', ' + row.property.location.stateProvince : ''}, ${row.property && row.property.location.countryCode}`),
            this.h('p', { class: 'sub-text' }, `${row.property && ![null, '-'].includes(row.property.contacts.phone) ? ' +' + row.property.contacts.phone : ''}`),
            this.h('p', { class: 'sub-text' }, `${row.property.contacts.email}`)
          ])
        },
        key: 'property'
      }
    }
  },
  orderReference: {
    label: 'Order reference',
    description: 'Reference id',
    level: 0,
    alwaysOn: true,
    select: [
      '"order"."orderReference" as "order.orderReference"'
    ],
    sortOptions: {
      orderReference: {
        col: '"order"."orderReference"',
        label: 'Order reference'
      }
    },
    reportColumns: [
      {
        col: 'order.orderReference',
        label: 'Order reference',
        align: 'left',
        width: 120
      }
    ],
    render () {
      return {
        props: {
          align: 'left',
          width: '140',
          prop: '"order"."orderReference"',
          label: 'Order reference',
          resizable: false,
          sortable: 'custom'
          // fixed: 'left',
        },
        scopedSlots: {
          default: ({ row }) => row.order.orderReference
        },
        key: 'orderReference'
      }
    }
  },
  appLabel: {
    label: 'App',
    description: 'Application name',
    level: 0,
    select: [
      '"app"."label" as "app.label"'
    ],
    sortOptions: {
      appLabel: {
        col: '"app"."label"',
        label: 'Order reference'
      }
    },
    reportColumns: [
      {
        col: 'app.label',
        label: 'App',
        align: 'left',
        width: 150
      }
    ],
    render () {
      return {
        props: {
          align: 'left',
          minWidth: '120',
          prop: '"app"."label"',
          label: 'App',
          sortable: 'custom',
          showOverflowTooltip: true
        },
        scopedSlots: {
          default: ({ row }) => this.h('span', row.app && row.app.label)
        },
        key: 'appLabel'
      }
    }
  },
  primaryGuest: {
    label: 'Primary guest',
    description: 'Primary person who going to stay in',
    level: 0,
    select: [
      '"booking"."primaryGuest" as "booking.primaryGuest"',
      '"booking"."primaryGuestNationality" as "booking.primaryGuestNationality"'
    ],
    sortOptions: {
      primaryGuest: {
        col: '"booking"."primaryGuest"',
        label: 'Primary guest'
      },
      primaryGuestNationality: {
        col: '"booking"."primaryGuestNationality"',
        label: 'Primary guest\'s nationality'
      }
    },
    reportColumns: [
      {
        col: 'booking.primaryGuest',
        label: 'Primary guest',
        align: 'left',
        width: 220
      },
      {
        col: 'booking.primaryGuestNationality',
        label: 'Primary guest\'s nationality',
        align: 'left',
        width: 40
      }
    ],
    render () {
      return {
        props: {
          align: 'left',
          width: '200',
          prop: '"booking"."primaryGuest"',
          label: 'Primary guest (Nationality)',
          sortable: 'custom'
        },
        scopedSlots: {
          default: ({ row }) => ([
            row.booking.primaryGuest,
            this.h('p', {
              class: 'sub-text'
            }, row.booking.primaryGuestNationality)
          ])
        },
        key: 'primaryGuest'
      }
    }
  },
  requestedAt: {
    label: 'Requested at',
    description: 'When was this order been placed',
    level: 0,
    select: [
      '"order"."createdAt" as "order.createdAt"'
    ],
    sortOptions: {
      requestedAt: {
        col: '"order"."createdAt" ',
        label: 'Requested at'
      }
    },
    reportColumns: [
      {
        col: 'order.createdAt',
        label: 'Requested at (UTC)',
        align: 'left'
      }
    ],
    render () {
      return {
        props: {
          align: 'left',
          width: '170',
          prop: '"order"."createdAt"',
          label: 'Requested at',
          resizable: false,
          sortable: 'custom'
        },
        scopedSlots: {
          default: ({ row }) => this.h('span', this.filters.date(row.order.createdAt)),
          header: ({ column }) => ([
            column.label,
            ' ',
            this.h('el-tooltip', {
              props: {
                placement: 'top',
                content: 'Date is display in your timezone'
              }
            }, [this.h('i', { class: { 'el-icon-question': true } })])
          ])
        },
        key: 'requestedAt'
      }
    }
  },
  roomCount: {
    label: 'Rooms',
    description: 'Number of rooms',
    level: 0,
    select: [
      '"booking"."roomCount" as "booking.roomCount"'
    ],
    sortOptions: {
      roomCount: {
        col: '"booking"."roomCount"',
        label: 'Rooms'
      }
    },
    reportColumns: [
      {
        col: 'booking.roomCount',
        label: 'Rooms',
        align: 'right',
        type: 'number',
        width: 40
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '90',
          prop: '"booking"."roomCount"',
          label: 'Rooms',
          sortable: 'custom',
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => this.h('span', row.booking.roomCount)
        },
        key: 'roomCount'
      }
    }
  },
  roomDescription: {
    label: 'Room description',
    description: 'Room type and meal plan',
    level: 0,
    select: [
      '"booking"."supplierDescription" as "booking.supplierDescription"',
      '"booking"."foodCode" as "booking.foodCode"'
    ],
    sortOptions: {
      supplierDescription: {
        col: '"booking"."supplierDescription"',
        label: 'Room description'
      },
      foodCode: {
        col: '"booking"."foodCode"',
        label: 'Meal plan'
      }
    },
    reportColumns: [
      {
        col: 'booking.supplierDescription',
        label: 'Supplier description',
        align: 'left',
        width: 360
      },
      {
        col: 'booking.foodCode',
        label: 'Meal plan',
        align: 'left',
        width: 40
      }
    ],
    render () {
      return {
        props: {
          align: 'left',
          minWidth: '280',
          className: '',
          prop: '"booking"."supplierDescription"',
          label: 'Room description',
          sortable: 'custom'
          // showOverflowTooltip: true,
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', { class: 'pri' }, row.booking.supplierDescription),
            this.h('p', { class: 'sub-text' }, this.filters.foodCode(row.booking.foodCode))
          ])
        },
        key: 'roomDescription'
      }
    }
  },
  stayPeriods: {
    label: 'Staying periods (YYYY-MM-DD)',
    description: 'Check-in and check-out dates',
    level: 0,
    select: [
      '"booking"."checkInDate" as "booking.checkInDate"',
      '"booking"."checkOutDate" as "booking.checkOutDate"'
    ],
    sortOptions: {
      checkInDate: {
        col: '"booking"."checkInDate"',
        label: 'Check-in date'
      },
      checkOutDate: {
        col: '"booking"."checkOutDate"',
        label: 'Check-out date'
      }
    },
    reportColumns: [
      {
        col: 'booking.checkInDate',
        label: 'Check-in date',
        align: 'left'
      },
      {
        col: 'booking.checkOutDate',
        label: 'Check-out date',
        align: 'left'
      }
    ],
    render () {
      return {
        props: {
          align: 'left',
          minWidth: '230',
          prop: '"booking"."checkOutDate"',
          label: 'Staying periods (YYYY-MM-DD)',
          resizable: false,
          sortable: 'custom'
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', { class: 'semibold' }, [row.booking.checkInDate, ' ', this.h('i', { class: 'el-icon-minus has-text-grey-light' }), ' ', row.booking.checkOutDate]),
            this.h('p', { class: 'sub-text' }, [
              this.filters.countDays(row.booking.checkInDate, { endDate: row.booking.checkOutDate }),
              ' ',
              'nights'
            ])
          ])
        },
        key: 'stayPeriods'
      }
    }
  },
  profitInCostCcy: {
    label: 'Profit',
    description: 'Profit after less transaction fees',
    level: 0,
    select: [
      '"order"."profitInCostCurrency" as "order.profitInCostCurrency.val"',
      '"order"."costCcy" as "order.profitInCostCurrency.ccy"'
    ],
    sortOptions: {
      totalAmount: {
        col: '"order"."profitInCostCurrency"',
        label: 'Profit'
      }
    },
    reportColumns: [
      {
        col: 'order.profitInCostCurrency.val',
        label: 'Profit',
        align: 'right',
        type: 'number',
        width: 130
      },
      {
        col: 'order.profitInCostCurrency.ccy',
        label: 'Profit CCY',
        align: 'center',
        width: 80
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '170',
          prop: '"order"."profitInCostCurrency"',
          label: 'Profit',
          resizable: false,
          sortable: 'custom'
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', { class: 'semibold' }, this.filters.ccyObject(row.order.profitInCostCurrency)),
            this.h('p', { class: 'sub-text' }, row.order.profitInCostCurrency ? row.order.profitInCostCurrency.ccy : '')
          ])
        },
        key: 'profitInCostCcy'
      }
    }
  },
  totalAmount: {
    label: 'Total amount',
    description: 'Total amount before adjustment',
    level: 0,
    select: [
      '"order"."totalAmount" as "order.totalAmount.val"',
      '"order"."totalAmountCcy" as "order.totalAmount.ccy"'
    ],
    sortOptions: {
      totalAmount: {
        col: '"order"."totalAmount"',
        label: 'Total amount'
      }
    },
    reportColumns: [
      {
        col: 'order.totalAmount.val',
        label: 'Total amount',
        align: 'right',
        type: 'number',
        width: 130
      },
      {
        col: 'order.totalAmount.ccy',
        label: 'Total amount CCY',
        align: 'center',
        width: 80
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '170',
          prop: '"order"."totalAmount"',
          label: 'Total amount',
          resizable: false,
          sortable: 'custom'
        },
        scopedSlots: {
          default: ({ row }) => this.h('span', { class: 'semibold' }, this.filters.ccyObject(row.order.totalAmount))
        },
        key: 'totalAmount'
      }
    }
  },
  totalChargedAmount: {
    label: 'Total charged amount',
    description: 'Amount charged to customer',
    level: 0,
    select: [
      '"order"."totalChargeAmount" as "order.totalChargeAmount.val"',
      '"order"."totalChargeAmountCcy" as "order.totalChargeAmount.ccy"'
    ],
    sortOptions: {
      totalAmount: {
        col: '"order"."totalChargeAmount"',
        label: 'Total charged amount'
      }
    },
    reportColumns: [
      {
        col: 'order.totalChargeAmount.val',
        label: 'Total charged amount',
        align: 'right',
        type: 'number',
        width: 130
      },
      {
        col: 'order.totalChargeAmount.ccy',
        label: 'Total charged amount CCY',
        align: 'center',
        width: 80
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '180',
          prop: '"order"."totalChargeAmount"',
          label: 'Total charged amount',
          resizable: false,
          sortable: 'custom'
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', { class: 'semibold' }, this.filters.ccyObject(row.order.totalChargeAmount)),
            this.h('p', { class: 'sub-text' }, row.order.totalChargeAmount ? row.order.totalChargeAmount.ccy : '')
          ])
        },
        key: 'totalChargedAmount'
      }
    }
  },
  glCode: {
    label: 'GL Code',
    description: 'GL Code / Department Code',
    level: 0,
    select: [
      '"order"."metadata" ->> \'glCode\' as "order.glCode"'
    ],
    reportColumns: [
      {
        col: 'order.glCode',
        label: 'GL Code / Department Code',
        align: 'right',
        type: 'number',
        width: 130
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '210',
          prop: '"order"."glCode"',
          label: 'GL Code / Department Code',
          sortable: 'custom',
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => this.h('span', row.order.glCode)
        },
        key: 'glCode'
      }
    }
  },
  reason: {
    label: 'Reason for selection',
    description: 'Reason for selection',
    level: 0,
    select: [
      '"order"."metadata" ->> \'reason\' as "order.reason"'
    ],
    reportColumns: [
      {
        col: 'order.reason',
        label: 'Reason for selection',
        align: 'right',
        type: 'number',
        width: 130
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '210',
          prop: '"order"."reason"',
          label: 'Reason for selection',
          sortable: 'custom',
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => this.h('span', row.order.reason)
        },
        key: 'reason'
      }
    }
  },
  membershipID: {
    label: 'Membership ID',
    description: 'Membership Rewards',
    level: 0,
    select: [
      '"booking"."roomGuests" as "booking.roomLeadGuests"'
    ],
    reportColumns: [
      {
        col: 'booking.membershipRewardId',
        label: 'Membership ID',
        align: 'right',
        type: 'number',
        width: 130
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '210',
          prop: '"booking.membershipRewardId"',
          label: 'Membership ID',
          sortable: 'custom',
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => this.h('span', row.booking.membershipRewardId)
        },
        key: 'membershipID'
      }
    }
  },
  mealVoucher: {
    label: 'Meal Voucher info',
    description: 'Meal Vouchers',
    level: 0,
    select: [],
    reportColumns: [
      {
        col: 'order.sumMealVouchers',
        label: 'Meal Voucher info',
        align: 'right',
        width: 80
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '150',
          prop: '"order"."sumMealVouchers"',
          label: 'Meal Voucher info',
          sortable: false,
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => {
            const renders = []
            const keys = row.order.sumMealVouchers.split('|')
            if (keys.length > 0) {
              keys.forEach((element, idx) => {
                if (element !== 0) {
                  renders.push(this.h('span', `${element}`))
                  renders.push(this.h('br'))
                }
              })
            }
            return (renders)
          }
        },
        key: 'mealVoucher'
      }
    }
  },
  remarks: {
    label: 'Remarks',
    description: 'Remarks',
    level: 0,
    select: [],
    reportColumns: [
      {
        col: 'booking.remarks',
        label: 'Remarks',
        align: 'right',
        width: 80
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '300',
          prop: '"booking"."remarks"',
          label: 'Remarks',
          sortable: false,
          resizable: false
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', `${row.booking.remarks}`)
          ])
        },
        key: 'remarks'
      }
    }
  },
  clientCommissionPct: {
    label: 'Commission %',
    description: 'Percentage of commission',
    level: 0,
    select: [
      '"order"."clientCommissionPct" as "order.clientCommissionPct"'
    ],
    sortOptions: {
      clientCommissionPct: {
        col: '"order"."clientCommissionPct"',
        label: 'Commission %'
      }
    },
    reportColumns: [
      {
        col: 'order.clientCommissionPct',
        label: 'Commission %',
        align: 'right',
        type: 'number',
        width: 180
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '180',
          prop: '"order"."clientCommissionPct"',
          label: 'Commission %',
          sortable: 'custom',
          showOverflowTooltip: true
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', row.order.clientCommissionPct ? this.filters.pct(row.order.clientCommissionPct) : 0)
          ])
        },
        key: 'clientCommissionPct'
      }
    }
  },
  clientCommission: {
    label: 'Commission Amount',
    description: 'Commission Amount to customer',
    level: 0,
    select: [
      '"order"."clientCommission" as "order.clientCommission.val"',
      '"order"."clientCommissionCurrency" as "order.clientCommission.ccy"'
    ],
    sortOptions: {
      clientCommission: {
        col: '"order"."clientCommission"',
        label: 'Commission Amount'
      }
    },
    reportColumns: [
      {
        col: 'order.clientCommission.val',
        label: 'Commission Amount',
        align: 'right',
        type: 'number',
        width: 130
      },
      {
        col: 'order.clientCommission.ccy',
        label: 'Commission Amount CCY',
        align: 'center',
        width: 80
      }
    ],
    render () {
      return {
        props: {
          align: 'right',
          minWidth: '180',
          prop: '"order"."clientCommission"',
          label: 'Commission Amount',
          resizable: false,
          sortable: 'custom'
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', { class: 'semibold' }, row.order.clientCommission ? this.filters.ccyObject(row.order.clientCommission) : '-'),
            this.h('p', { class: 'sub-text' }, row.order.clientCommission ? row.order.clientCommission.ccy : '')
          ])
        },
        key: 'clientCommission'
      }
    }
  },
  createdBy: {
    label: 'Booked By',
    description: 'Information of Person who booked.',
    level: 0,
    select: [
      '"customer"."displayName" as "customer.displayName"',
      '"customer"."email" as "customer.email"'
    ],
    reportColumns: [
      {
        col: 'customer.displayName',
        label: 'Customer\'s name',
        align: 'left',
        width: 180
      },
      {
        col: 'customer.email',
        label: 'Customer\'s email',
        align: 'left',
        width: 220
      }
    ],
    render () {
      return {
        props: {
          align: 'left',
          minWidth: '180',
          prop: '"customer"."displayName"',
          label: 'Booked By',
          sortable: 'custom'
        },
        scopedSlots: {
          default: ({ row }) => ([
            this.h('span', row.customer.displayName),
            this.h('p', { class: 'sub-text' }, row.customer.email)
          ])
        },
        key: 'createdBy'
      }
    }
  }
}

/**
 * Cache for later use
 */
const colCodes = Object.keys(cols)

const list = colCodes.map(key => ({ key, ...cols[key] }))

/**
 * Validate colmuns keys, invalid key will be removed
 * @param reqCols<string[]> - requested colmuns
 * @return <string[]> - santized keys.
 */
const validate = (reqCols = []) => {
  if (!Array.isArray(reqCols)) throw new TypeError('reqCols must be an array')
  return reqCols.filter(k => colCodes.includes(k))
}

/**
 * Retrive colmns insance from key
 * @param {string} colCode – code to represent the column
 * @param {number} level – optional. if level pass in we will validate >=
 * @return null - if level is too low. intance - if okie. undefined – if the colCode not defined yet
 */
const findByKey = (colCode, level) => {
  const inst = cols[colCode]
  /**
   * Ealry exits if no level validation
   */
  if (level == null) return inst

  return inst.level <= level ? inst : null
}

export default {
  cols,
  list,
  validate,
  findByKey
}
