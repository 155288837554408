<template>
  <div class="list is-gapless is-comfort wrap">
    <slot name="prepend" />
    <div class="list-content">
      <div class="list-label">Stay at</div>
      <div class="list-value has-text-weight-semibold">{{ name }}</div>
      <div class="is-size-7"><i class="el-icon-location"/> {{ location.address }} {{ location.city }} {{ location.country }} {{ location.postalCode }}</div>
      <StarRatings class="is-hidden-tablet" :ratings="starRating ? starRating : 0" />
      <!-- <el-button type="text" size="small">Open in map</el-button> -->
    </div>
    <div class="list-action is-hidden-mobile">
      <StarRatings :ratings="starRating ? starRating : 0" />
    </div>
    <!-- <div class="list-content list-fullwidth has-text-centered">
      <el-button
        type="text"
        size="small"><i class="el-icon-arrow-down" /> Expand for more property details</el-button>
    </div> -->
  </div>
</template>

<script>
import StarRatings from './StarRatings'

export default {
  name: 'OrderProperty',
  components: {
    StarRatings
  },
  props: {
    name: String,
    location: Object,
    starRating: Number
  }
}
</script>

<style lang="scss" scoped>

</style>
