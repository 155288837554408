<template>
  <div class="payment-histories">
    <div class="list is-gapless  is-align-flex-start has-border-bottom list-clickable"
      v-for="(item,index) in history" :key="index" :class="{active:current===index}" @click="handleHistorySelected(index)">
      <div class="list-content">
        <div class="list-value">
          {{ item | eventFilter }}
        </div>
        <div class="list-label" v-if="item.eventName === 'booking.v3_failed'">
          {{ item.data && item.data.failure_reason && item.data.failure_reason.text }}
        </div>
      </div>
      <div class="list-action">
        <span v-if="item.data && item.data.type === 'mail'">
          <el-tag
            :type="item.data && item.data.status === 'sent' ? 'success': 'warning'">
            {{ item.data && item.data.status }}
          </el-tag>
        </span>
        <span v-if="item.data && item.data.type === 'fax'">
          <el-tooltip>
            <el-tag
              :type="item.data && item.data.status === 0 ? 'success': 'warning'">
              {{ getFaxStatus(item.data && item.data.status) }}
            </el-tag>
            <span slot="content">
              Status Code: {{ item.data.status }}
              <br>
              Click <a href="https://uplandsoftware.com/interfax/help/ss-help/ss-documentation/#interfax-status-codes" target="_blank">here</a> for more details
            </span>
          </el-tooltip>
        </span>
        &nbsp;
        <span>{{ item.timestamp | date }}</span>
      </div>
    </div>
    <PpModal v-model="modalVisibility" :isLoading="false" is-click-outside-close @closed="handleCloseModal">
      <div class="is-pulled-right"><i class="el-icon-close" @click="modalVisibility=false" style="font-size: 24px;"></i></div>
      <div class="box">
        <div class="box-header"><span class="has-text-weight-semibold has-text-primary">Event data - {{selectedHistoryEventData.type}} ({{selectedHistoryEventData.eventName}})</span></div>
        <div class="box-content" v-if="selectedHistoryEventData.type === 'api_call'">
          <strong>Request</strong>
          <pre>{{ selectedHistoryEventData.data.request }}</pre>
          <br>
          <strong>Response</strong>
          <pre>{{ selectedHistoryEventData.data.response }}</pre>
        </div>
        <div class="box-content" v-else>
          <pre>{{ selectedHistoryEventData.data }}</pre>
        </div>
      </div>
    </PpModal>
  </div>
</template>

<script>

export default {
  name: 'OrderHistory',
  props: {
    history: {
      require: true
    }
  },
  data () {
    return {
      current: -1,
      modalVisibility: false
    }
  },
  filters: {
    orderStatus (item) {
      switch (item.orderStatus) {
        case 'PROCESSING':
          return 'Processing on checkout page'
        case 'PENDING':
          return 'Pending confimation from suppliers'
        case 'PAYMENT_PENDING':
          return 'Pending confimation from payment gateway'
        case 'FAILURE':
          return 'Fail to book from suppliers'
        case 'CONFIRMED':
          if (item.eventName === 'booking.cancel_in_progress') {
            return 'Request to cancel the booking to supplier'
          }
          return 'Confimation from payment gateway and suppliers'
        case 'CANCELLED':
          if (item.eventName === 'payment.refund_failed') {
            return 'Fail to Refund (can click on refund button again)'
          }
          return 'Cancelled sucessfully'
        case 'REFUND_IN_PROGRESS':
          return 'Refund In-Progress'
        case 'REFUND_FAILURE':
          return 'Fail to Refund (Can click on refund button again)'
        default:
          return ''
      }
    },
    eventFilter (item) {
      switch (item.eventName) {
        case 'order.created':
          return 'The order has been created (created a record in database)'
        case 'booking.created':
          return 'The booking has been created (created a record in database)'
        case 'booking.prebook':
          return 'Call v3 API to do "Prebook"'
        case 'booking.postbook':
          return 'Call v3 API to do “Postbook” (to confirm with supplier that user has authorized the payment)'
        case 'booking.v3_pending':
          return 'Pending confirmation from suppliers'
        case 'booking.v3_pending_failed':
        case 'booking.v3_failed':
          return 'Supplier failed to confirm the booking'
        case 'booking.v3_cancelled':
          return 'The booking has been cancelled on supplier side'
        case 'booking.v3_pending_confirmed':
        case 'booking.v3_confirmed':
          return 'Supplier confirmed the booking'
        case 'booking.cancel_in_progress':
          return 'Booking cancellation is in progress'
        case 'booking.cancel_failure':
          return 'The booking cancellation is failed'
        case 'payment.created':
          return 'The payment has been created (created a record in database)'
        case 'payment.authorized':
          return 'Payment passed authorization from gayment gateway '
        case 'payment.paid':
          return 'The payment has been authorized (user hasn’t been charged yet)'
        case 'payment.cancel':
          return 'The payment has been cancelled before captured'
        case 'payment.pending':
          return 'Submit payment to payment gateway'
        case 'payment.failed':
          return 'The payment is failed. Click to see the decline message.'
        case 'payment.capture_in_progress':
          return 'Notified payment gateway to capture the payment amount (user has been charged)'
        case 'payment.capture':
          return 'The payment has been captured by payment gateway'
        case 'payment.capture_failed':
          return 'The payment cannot be captured'
        case 'payment.refund_in_progress':
          return 'Notified payment gateway to refund the payment'
        case 'payment.refund_failed':
          return 'The payment gateway failed to refund the payment'
        case 'payment.refund':
          if (item.data && item.data.success) {
            if (item.data.success === 'false') {
              return 'The payment gateway failed to refund the payment'
            }
          }
          return 'Cancelled & Refunded Successfully'
        default:
          return item.eventName
      }
    }
  },
  computed: {
    selectedHistoryEventData () {
      if (this.current === -1) return {}
      return this.history[this.current]
    }
  },
  methods: {
    handleHistorySelected (index) {
      this.current = index
      this.modalVisibility = true
    },
    handleCloseModal () {
      this.current = -1
    },
    getFaxStatus (statusCode) {
      let result = ''
      if (statusCode === 0) {
        result = 'sent'
      } else if (statusCode < 0) {
        result = 'in-progress'
      } else {
        result = 'fail'
      }

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~element-ui/packages/theme-chalk/src/common/var";

  .list-clickable {
    cursor: pointer;

    &:hover {
      background-color: $--table-row-hover-background-color;
    }

    &.active {
      background-color: $--table-current-row-background-color;
    }
  }
</style>
