<template>
  <el-drawer :visible.sync="show" size="60%" @closed="handleModalClosed" class="details--modal">
    <template v-slot:title>
      <div class="list is-topless is-bottomless has-background-white-bis">
        <div class="list-content">
          <div class="list-value has-text-grey">
            <el-tooltip content="Your order code">
                <span class="has-text-dark has-text-weight-semibold">
                  <i class="el-icon-document"/> {{ orderReference }}
                  <el-tag v-if="order.previewMode" size="small" type="danger">PREVIEW</el-tag>
                </span>
            </el-tooltip>
          </div>
        </div>
        <div class="list-action">
          <!-- TODO: REMOVE HCBK and should be parameterized based on feature -->
          <el-button
            v-if="faxEnabled && checkPermission(['SEND_FAX']) &&
              payment.paymentGateway === 'pay_at_hotel' &&
              order.status &&
              order.status.code === 'CONFIRMED'"
            type="primary"
            size="mini"
            style="margin-right: 10px"
            icon="el-icon-s-promotion"
            @click="sendFaxModalActive=true">
            Send CC info to Hotel
          </el-button>
          <el-button
            v-if="order.status && (order.status.code === 'CONFIRMED' || order.status.code === 'CANCELLED' || order.status.code === 'FAILURE')"
            :type="order.status && order.status.code === 'CONFIRMED' ? 'primary' : 'danger'"
            size="mini"
            style="margin-right: 10px"
            icon="el-icon-s-promotion"
            @click="sendEmailModalActive=true">
            Send {{ order.status && order.status.code === 'CONFIRMED' ? 'Confirmation' : 'Cancellation' }} Email
          </el-button>
          <span
            v-if="order.status"
            :class="['card-booking-status', order.status.tone]">
            {{ order.status && order.status.label }}
          </span>
        </div>
      </div>
    </template>
    <el-scrollbar class="el-scrollbar__wrap_h" style="height: 100%;overflow-x: hidden;" v-loading="loading">
      <PpCard class="details--content">
        <div class="has-background-white-bis" style="position: sticky; z-index: 1; top: 0;">
          <div class="tabs is-boxed is-fullwidth">
            <ul>
              <li class="is-active">
                <a>Order details</a>
              </li>
              <li>
              </li>
            </ul>
          </div>
        </div>
        <div class="box is-stick">
          <div class="list">
            <div class="list-content">
              <div class="list-value has-text-grey">Requested at</div>
            </div>
            <div class="list-action is-uppercase">{{ order.requestedAt | date }}</div>
          </div>
          <div class="list">
            <div class="list-content">
              <div class="list-value has-text-grey">Hotel Confirmation ID</div>
            </div>
            <div class="list-action is-uppercase">{{ booking.hotelConfirmationId || '-' }}</div>
          </div>
          <div class="list" v-if="booking && booking.roomLeadGuests && booking.roomLeadGuests.length > 0 && booking.roomLeadGuests[0].membershipId">
            <div class="list-content">
              <div class="list-value has-text-grey">Membership Rewards ID #:</div>
            </div>
            <div class="list-action is-uppercase">{{ booking.roomLeadGuests[0].membershipId }}</div>
          </div>
          <div class="list" v-if="order.metadata && order.metadata.glCode">
            <div class="list-content">
              <div class="list-value has-text-grey">Gl Code</div>
            </div>
            <div class="list-action is-uppercase">{{ order.metadata.glCode }}</div>
          </div>
        </div>
        <div class="box is-stick">
          <div class="box-header">
            <span class="has-text-weight-semibold has-text-primary">Contact person</span>
          </div>
          <div class="box-content">
            <div class="list is-gapless is-topless">
              <div class="list-content">
                <div class="list-label">Name</div>
                <div class="list-value has-text-weight-semibold">
                  {{ contactPerson.fullName }}
                </div>
              </div>
              <div class="list-content">
                <div class="list-label">Email</div>
                <div class="list-value has-text-weight-semibold">
                  {{ contactPerson.email }}
                </div>
              </div>
              <div class="list-content">
                <div class="list-label">Contact no</div>
                <div class="list-value has-text-weight-semibold">
                  {{ contactPerson.contactNo }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box is-stick">
          <div class="box-content">
            <OrderProperty
              v-if="property.name" v-bind="property">
              <PropertyGalleryHover
                class="list-action"
                slot="prepend"
                hasGalleryHidden
                :heroImage="property.heroImage"
              />
            </OrderProperty>
          </div>
        </div>
        <div class="box is-stick">
          <div class="box-content">
            <OrderDetails v-if="booking" class="" v-bind="booking"/>
          </div>
        </div>
        <div class="box is-stick"
             v-for="(it, idx) in booking && booking.roomLeadGuests"
             :key="it.firstName + idx">
          <div class="box-content" style="padding-top: 12px; padding-bottom: 12px;">
            <div class="list is-comfort is-gapless wrap">
              <div class="list-pre list-is-12-mobile" style="min-width: 80px;">
                <div class="list-value has-text-weight-semibold">Room {{idx + 1}}</div>
              </div>
              <div class="list-content list-is-12-mobile">
                <!-- <small>TODO: UI need to change</small> -->
                <div class="list-label">Reserved for:</div>
                <div class="list-value has-text-weight-semibold">
                  {{ it.firstName }} {{ it.lastName }}
                  <span v-show="it.nationality" class="">({{ it.nationality }})</span>
                  <span v-show="it.mealVoucher != null"> Meal: {{ getMealVoucher(it.mealVoucher) }} </span>
                </div>
                <div class="list-value has-text-weight-semibold"
                  v-if="it.roomMembers"
                  v-for="(member, idxMember) in it.roomMembers"
                  :key="member.firstName + idxMember">
                  {{ member.firstName }} {{ member.lastName }}
                  <span v-show="member.nationality" class="">({{ member.nationality }})</span>
                  <span v-show="member.mealVoucher != null"> Meal: {{ getMealVoucher(member.mealVoucher) }} </span>
                </div>
              </div>
              <div class="list-content list-is-12-mobile">
                <div class="list-label">Remarks</div>
                <div class="list-value" v-html="formattedRemarks(it.remarks)"></div>
              </div>
            </div>
          </div>
          <!-- Room Members -->

        </div>
        <div class="box is-stick">
          <div class="box-header">
            <span class="has-text-weight-semibold has-text-primary">Cancellation policy</span>
            <div class="is-pulled-right">
              <el-button
                type="danger"
                size="small"
                @click="handleCancelOrder"
                v-if="checkPermission(['MODIFY_ORDER']) && order.status && order.status.code === 'CONFIRMED'">
                Cancel booking
              </el-button>
            </div>
          </div>
          <div class="box-content">
            <div class="list is-gapless wrap is-topless">
              <CancellationParse
                v-if="order.metadata"
                class="list-content list-is-12-mobile"
                :freeCancellationBefore="booking.freeCancellationBefore"
                :freeCancellationDateTz="order.metadata.freeCancellationDateTz" />
            </div>
            <div class="list is-gapless is-comfort" style="padding-bottom: 0;"
                 v-if="booking.metadata && booking.metadata.cancellationPolicy && booking.metadata.cancellationPolicy.remarks">
              <div class="content is-small">{{ booking.metadata && booking.metadata.cancellationPolicy && booking.metadata.cancellationPolicy.remarks }}</div>
            </div>
          </div>
        </div>
        <div class="box is-stick">
          <div class="box-header">
            <div class="has-text-weight-semibold has-text-primary">Additional rules and restrictions</div>
          </div>
          <div class="box-content">
            <div class="content is-small"
                 v-if="booking.checkInInstructions"
                 v-html="booking.checkInInstructions"></div>
            <div class="list is-small is-gapless">
              <div class="list-content">
                <div class="list-label">Check-in time</div>
                <div>{{ property.checkInTime | checkInOutTime }}</div>
              </div>
              <div class="list-content">
                <div class="list-label">Check-out time</div>
                <div>{{ property.checkOutTime | checkInOutTime }}</div>
              </div>
              <!-- <div class="list-content">
                <div class="list-label">Payment type</div>
                <div>{{ property.payments || '–' }}</div>
              </div> -->
              <div class="list-content">
                <div class="list-label">Nearest airport</div>
                <div>{{ property.airportCode || '–' }}</div>
              </div>
            </div>
            <div class="gap"></div>
            <div class="content is-small">
              <p>
                <b>Please note that hotels update their internal records (such as guest name, room type, booking number, etc.) any time from the moment a booking is made until the date of check-in. Please do not hesitate to contact us if you have any queries regarding your booking.</b>
              </p>
            </div>
          </div>
        </div>
        <div class="box is-stick">
          <div class="box-header">
            <div class="has-text-weight-semibold has-text-primary">Price</div>
          </div>
          <div class="box-content">
            <div class="list is-gapless is-narrow is-topless">
              <div class="list-content">
                <div class="list-value has-text-grey">Total amount</div>
              </div>
              <div :class="['list-action is-uppercase']">
                <span :class="['has-text-dark']">{{ totalPreAdjustment | currencyObj }} ({{totalPreAdjustment && totalPreAdjustment.ccy}})</span>
              </div>
            </div>
            <div class="list has-border-bottom is-gapless is-narrow is-topless">
              <div class="list-content">
                <div class="list-value has-text-grey">Total adjustment</div>
              </div>
              <div :class="['list-action is-uppercase']">
                <span :class="['has-text-dark']">{{ order.finalAdjustmentAmount | currencyObj }} ({{order.finalAdjustmentAmount && order.finalAdjustmentAmount.ccy}})</span>
              </div>
            </div>
            <div class="list  is-gapless is-narrow">
              <div class="list-content">
                <div class="list-value has-text-grey">Total charged amount</div>
              </div>
              <div :class="['list-action is-uppercase']">
                <span :class="['has-text-weight-semibold has-text-dark']">{{ order.totalChargeAmount | currencyObj }} ({{order.totalChargeAmount && order.totalChargeAmount.ccy}})</span>
              </div>
            </div>
          </div>
        </div>
        <div class="box is-stick">
          <div class="box-header">
            <div>
              <span class="has-text-weight-semibold has-text-primary">Payment</span>
              <el-tag v-if="payment.paymentGateway === 'pay_at_hotel'" size="small" type="success" effect="plain" style="margin-left: .75rem">Pay at hotel</el-tag>
            </div>
            <div class="is-pulled-right">
              <div class="is-pulled-right">
                <el-button
                  type="danger"
                  size="small"
                  @click="handleRefundPayment"
                  v-if="checkPermission(['MODIFY_ORDER']) && refundable">
                  Refund
                </el-button>
              </div>
            </div>
          </div>
          <div class="box-content">
            <div class="list is-gapless is-topless is-align-flex-start">
              <div class="list-content">
                <div class="list-label">Status</div>
                <div class="list-value has-text-weight-semibold is-uppercase">
                  {{ payment && payment.status }}
                </div>
              </div>
              <div class="list-content">
                <div class="list-label">Paid</div>
                <div class="list-value">
                  <p class="has-text-weight-semibold">
                  <span v-if="payment.paid" class="has-text-success">
                    <i class="el-icon-check" /> YES
                  <!-- <p class="is-size-7">{{ payment.paidAt | date }}</p> -->
                  </span>
                  <span v-else class="">
                    NO
                  </span>
                  </p>
                </div>
              </div>
              <div class="list-content">
                <div class="list-label">Captured</div>
                <div class="list-value">
                  <p class="has-text-weight-semibold">
                  <span v-if="payment.captured" class="has-text-success">
                    <i class="el-icon-check" /> YES
                  <p class="is-size-7">{{ payment.paidAt | date }}</p>
                  </span>
                  <span v-else class="">
                    NO
                  </span>
                  </p>
                </div>
              </div>
              <div class="list-content">
                <div class="list-label">Refunded</div>
                <div class="list-value">
                  <p class="has-text-weight-semibold">
                  <span v-if="payment.refunded" class="has-text-success">
                    <i class="el-icon-check" /> YES
                    <!-- <p class="is-size-7">{{ payment.requestedAt | date }}</p> -->
                  </span>
                    <span v-else class="">
                    NO
                  </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="list is-gapless is-topless is-align-flex-start" v-if="payment.source">
              <div class="list-content">
                <div class="list-label">Payment gateway</div>
                <div class="list-value has-text-weight-semibold is-uppercase">
                  {{ payment && payment.paymentGateway }}
                </div>
              </div>
              <div class="list-content">
                <div class="list-label">Source</div>
                <div class="list-value has-text-weight-semibold">
                  {{ payment.source.brand }}
                </div>
              </div>
              <div class="list-content">
                <div class="list-label">Last 4</div>
                <div class="list-value has-text-weight-semibold">
                  {{ payment.source.last4 }}
                </div>
              </div>
            </div>
            <!-- {{ payment }} -->
            <!-- <div class="list is-gapless is-narrow is-topless">
              <div class="list-content">
                <div class="list-value has-text-grey">Total amount</div>
              </div>
              <div :class="['list-action is-uppercase']">
                <span :class="['has-text-dark']">{{ order.totalAmount | currencyObj }} ({{order.totalAmount.ccy}})</span>
              </div>
            </div> -->
            <div class="list is-narrow">
              <div class="list-content"></div>
            </div>
            <div class="list has-border-bottom is-gapless is-narrow is-topless">
              <div class="list-content">
                <div class="list-value has-text-grey">Charged amount</div>
              </div>
              <div :class="['list-action is-uppercase']">
                <span :class="['has-text-dark']">{{ isZeroDecimalCurrencies ? payment.amount : payment.amount / 100 | number }} ({{payment.currency}})</span>
              </div>
            </div>
            <div class="list  is-gapless is-narrow">
              <div class="list-content">
                <div class="list-value has-text-grey">Refunded amount</div>
              </div>
              <div :class="['list-action is-uppercase']">
                <span :class="['has-text-dark']" v-if="payment.paymentGateway === 'pay_at_hotel' && order.status && order.status.code === 'CANCELLED'">
                  {{ 0 | number }} ({{payment.currency}})
                </span>
                <span :class="['has-text-dark']" v-else>
                  {{ isZeroDecimalCurrencies ? payment.amountRefunded : payment.amountRefunded / 100 | number }} ({{payment.currency}})
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="history" class="box is-stick" style="margin-bottom: 100px !important">
          <div class="box-header">
            <span class="has-text-weight-semibold has-text-primary">Event Logs</span>
          </div>
          <div class="box-content">
            <OrderHistory :history="history"/>
          </div>
        </div>
      </PpCard>
    </el-scrollbar>
    <router-view />
    <SendEmailModal :email="contactPerson.email" :open.sync="sendEmailModalActive" :order="order" :orderReference="orderReference" @sent="fetchData"/>
    <SendFaxModal :open.sync="sendFaxModalActive" :order="order" :property="property" :orderReference="orderReference" @sent="fetchData"/>
  </el-drawer>
</template>

<script>
import { mapState } from 'vuex'
import { ordersOne, ordersOneHistory } from '../../factories/manage'
import CancellationParse from '../../components/CancellationParse'
import PropertyGalleryHover from '../../components/PropertyGalleryHover'
import OrderProperty from '../../components/OrderProperty'
import OrderDetails from '../../components/OrderDetails'
import lv2, { axiosErrorHandler } from '../../factories/lv2Api'
import OrderHistory from '../../components/OrderHistory'
import SendEmailModal from './SendEmailModal'
import SendFaxModal from './SendFaxModal'
import checkPermission from '@/plugins/permission'

const asyncPostCancelBooking = async (payload) => {
  try {
    const { data } = await lv2.post('/cancel/order', payload)
    // eslint-disable-next-line no-unused-vars
    const { outlets, meta } = data
    return outlets
  } catch (err) {
    const parseErr = axiosErrorHandler(err)
    throw parseErr
  }
}

const asyncPostRefundPayment = async (payload) => {
  try {
    const { data } = await lv2.post('/refund/order', payload)
    // eslint-disable-next-line no-unused-vars
    const { outlets, meta } = data
    return outlets
  } catch (err) {
    const parseErr = axiosErrorHandler(err)
    throw parseErr
  }
}

export default {
  name: 'ListDetails',
  components: {
    SendEmailModal,
    CancellationParse,
    PropertyGalleryHover,
    OrderProperty,
    OrderDetails,
    OrderHistory,
    SendFaxModal
  },
  props: {
    orderReference: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      show: true,
      sendEmailModalActive: false,
      faxButtonLoading: false,
      sendFaxModalActive: false,
      timeouts: []
    }
  },
  computed: {
    ...mapState({
      app: state => state.ordersDetails.app,
      order: state => state.ordersDetails.order,
      property: state => state.ordersDetails.property,
      payment: state => state.ordersDetails.payment,
      booking: state => state.ordersDetails.booking,
      contactPerson: state => state.ordersDetails.contactPerson,
      history: state => state.ordersDetails.history,
      zeroDecimalCurrencies: state => state.zeroDecimalCurrencies
    }),
    refundable () {
      const orderStatusRefundable = this.order && this.order.status && (this.order.status.code === 'FAILURE' || this.order.status.code === 'CANCELLED' || this.order.status.code === 'REFUND_FAILURE')
      const paymentStatusRefundable = this.payment && this.payment.paid && this.payment.captured && !this.payment.refunded
      return orderStatusRefundable && paymentStatusRefundable
    },
    totalPreAdjustment () {
      const totalAmount = this.order.totalAmount && this.order.totalAmount.val
      const adjustmentAmount = this.order.finalAdjustmentAmount && this.order.finalAdjustmentAmount.val
      const unitAdjustedAmount = totalAmount - adjustmentAmount
      return { val: unitAdjustedAmount, ccy: this.order.totalAmount && this.order.totalAmount.ccy }
    },
    faxEnabled () {
      return this.app && this.app.features && this.app.features.indexOf('ENABLE_FAX') !== -1
    },
    isZeroDecimalCurrencies () {
      return this.payment && this.payment.currency && this.zeroDecimalCurrencies.includes(this.payment.currency)
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    'order': {
      immediate: true,
      deep: true,
      handler (nV) {
        if (nV && this.app.clientCode && this.order.appCode) {
          ordersOneHistory({ orderReference: this.orderReference, clientCode: this.app.clientCode, appCode: this.order.appCode }).then(res => {
            if (res.details && res.details.history.length > 0) {
              const anyPending = res.details.history.findIndex(item => (item.data.type === 'fax' && item.data.status < 0) || (item.data.type === 'mail' && item.data.status === 'in-progress'))
              if (anyPending !== -1) {
                this.clearTimeouts()
                const timeout = setTimeout(() => {
                  ordersOneHistory({ orderReference: this.orderReference, clientCode: this.app.clientCode, appCode: this.order.appCode })
                }, 60000)
                this.timeouts.push(timeout)
              }
            }
          })
        }
      }
    }
  },
  methods: {
    checkPermission,
    fetchData () {
      this.loading = true
      ordersOne({ orderReference: this.orderReference }).then(() => {
        this.loading = false
      }).catch(err => this.loading = false)
    },
    formattedRemarks (val) {
      return val ? val.replace(/\n/g, '<br>') : ''
    },
    getMealVoucher (voucherCode) {
      switch (voucherCode) {
        case 0:
          return '$0'
        case 40:
          return '$40'
        case 60:
          return '$60'
        case 'all':
          return 'All Meals'
      }
      return '-'
    },
    handleModalClosed () {
      this.$router.go(-1)
    },
    handleCancelOrder () {
      this.$confirm(`
          Please check cancellation policy before doing cancellation. <br> Would you like to cancel this booking?`, 'Warning', {
        confirmButtonText: 'Yes, I agree',
        cancelButtonText: 'No, Keep This Booking',
        dangerouslyUseHTMLString: true,
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = 'Please wait...'
            this.postCancelOrder(done, instance)
          } else {
            done()
          }
        }
      })
    },
    postCancelOrder (callback, loader) {
      asyncPostCancelBooking({
        orderReference: this.orderReference,
        appCode: this.order.appCode,
        globalPreview: this.order.previewMode
      }).then((resp) => {
        this.$message({
          type: 'success',
          message: 'Booking has been cancelled. We are sorry to let you go :('
        })
        callback()
        loader.confirmButtonLoading = false
        ordersOne({ orderReference: this.orderReference })
      }).catch((err) => {
        this.$message({
          type: 'error',
          message: err.message
        })
        callback()
        loader.confirmButtonLoading = false
        this.handleModalClosed()
      })
    },
    handleRefundPayment () {
      this.$confirm(`Refund Process may take sometimes, Would you like to refund the payment?`, 'Warning', {
        confirmButtonText: 'Yes, I agree',
        cancelButtonText: 'Later',
        dangerouslyUseHTMLString: true,
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = 'Please wait...'
            this.postRefundPayment(done, instance)
          } else {
            done()
          }
        }
      })
    },
    postRefundPayment (callback, loader) {
      asyncPostRefundPayment({
        orderReference: this.orderReference,
        appCode: this.order.appCode,
        globalPreview: this.order.previewMode
      }).then((resp) => {
        this.$message({
          type: 'success',
          message: 'We will notify our customer through email once done.'
        })
        callback()
        loader.confirmButtonLoading = false
        ordersOne({ orderReference: this.orderReference })
      }).catch((err) => {
        this.$message({
          type: 'error',
          message: err.message
        })
        callback()
        loader.confirmButtonLoading = false
        this.handleModalClosed()
      })
    },
    clearTimeouts () {
      for (let i = 0; i < this.timeouts.length; i++) {
        clearTimeout(i)
      }
      this.timeouts.length = 0
    }
  },
  beforeDestroy () {
    this.clearTimeouts()
  }
}
</script>

<style lang="scss">
  @import '../../styles/bulma-variables';
  .details--modal /deep/ .pp-modal--content {
    max-width: 100%;
  }

  .details--modal /deep/ .pp-modal--content {
    max-width: 100%;
  }
  .details--content {
    width: 100%;
  }
  .el-message-box__wrapper{
    z-index: 9999 !important;
  }

  .el-drawer {
    background: $white-bis;
  }

  .el-drawer__body {
    height: 100%;
  }

  .el-scrollbar__wrap_h .el-scrollbar__wrap {
    overflow-x: hidden;
  }
</style>
