// End of date
const endOfDay = (date) => {
  try {
    const newDate = new Date(date)
    newDate.setHours(23)
    newDate.setMinutes(59)
    newDate.setSeconds(59)
    newDate.setMilliseconds(999)
    return newDate
  } catch (err) {
    throw err
  }
}

const startOfDay = (date) => {
  try {
    const newDate = new Date(date)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return newDate
  } catch (err) {
    throw err
  }
}

const fullDayRange = (range) => {
  if (!Array.isArray(range)) throw new TypeError('Invalid range, range must be an Array with date object')
  return [startOfDay(range[0]), endOfDay(range[1])]
}
export default {
  endOfDay,
  startOfDay,
  fullDayRange
}
