<script>
import filters from '@/plugins/filters'

import orderCols from '../../orderColumns'

export default {
  functional: true,
  name: 'ListColsLoader',
  props: {
    cols: {
      type: Array,
      required: true,
      default () {
        return [ ]
      }
    }
  },
  render (h, context) {
    return context.props.cols
      .map(col => h('el-table-column', orderCols.cols[col].render.call({ h, context, filters })))
  }
}
</script>
