const keys = {
  PROCESSING: {
    label: 'Processing',
    code: 'PROCESSING',
    ref: {
      table: 'orders',
      status: 'PROCESSING'
    }
  },
  PENDING: {
    label: 'Pending',
    code: 'PENDING',
    ref: {
      table: 'orders',
      status: 'PENDING'
    }
  },
  PAYMENT_PENDING: {
    label: 'Payment Pending',
    code: 'PAYMENT_PENDING',
    ref: {
      table: 'orders',
      status: 'PAYMENT_PENDING'
    }
  },
  CONFIRMED: {
    label: 'Confirmed',
    code: 'CONFIRMED',
    ref: {
      table: 'orders',
      status: 'CONFIRMED'
    }
  },
  FAILURE: {
    label: 'Failure',
    code: 'FAILURE',
    ref: {
      table: 'orders',
      status: 'FAILURE'
    }
  },
  CANCELLED: {
    label: 'Cancelled',
    code: 'CANCELLED',
    ref: {
      table: 'orders',
      status: 'CANCELLED'
    }
  },
  REFUND_IN_PROGRESS: {
    label: 'Refund In-Progress',
    code: 'REFUND_IN_PROGRESS',
    ref: {
      table: 'orders',
      status: 'REFUND_IN_PROGRESS'
    }
  },
  REFUND_FAILURE: {
    label: 'Refund Failure',
    code: 'REFUND_FAILURE',
    ref: {
      table: 'orders',
      status: 'REFUND_FAILURE'
    }
  },
  CANCELLATION_RECEIVED: {
    label: 'Cancellation Received',
    code: 'CANCELLATION_RECEIVED',
    tone: 'has-background-danger',
    ref: {
      table: 'orders',
      status: 'CANCELLATION_RECEIVED'
    }
  }
}

export const toArray = function () {
  const k = Object.keys(keys)
  return k.map(ele => keys[ele])
}

export default keys
