<template>
  <div>
    <p v-if="freeCancellationBefore"
      class="has-text-success is-flex" style="align-items: center;">
      <i class="el-icon-check icon-size" />&nbsp;
      <span class="">Free cancellation before</span>&nbsp;
      <span class="has-text-black-ter">{{ freeCancellationDate }} </span>
    </p>
  <p v-else
    class="has-text-danger is-flex" style="align-items: center;">
    <i class="el-icon-warning icon-size" />&nbsp;
    <span class="">This is a non-refundable room.</span>
  </p>
  </div>
</template>

<script>
export default {
  name: 'CancellationParse',
  props: {
    freeCancellationBefore: {
      required: true
    },
    freeCancellationDateTz: {
      required: false
    }
  },
  computed: {
    freeCancellationDate () {
      return this.freeCancellationDateTz ? this.freeCancellationDateTz
        : this.$options.filters.date(this.freeCancellationBefore, {
          timeZone: 'UTC',
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon-size {
    font-size: 1.25em;
  }
</style>
