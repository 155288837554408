<template>
  <article class="box">
    <div class="list">
      <div class="list-title">
        Select columns you want to see
      </div>
    </div>
    <div class="list">
      <div class="list-content">
        <PpCard class="cols-wrapper">
          <el-checkbox-group v-model="selectedCols">
            <el-checkbox
              :disabled="it.alwaysOn"
              class="grouped-checkbox"
              :label="it.key"
              v-for="it in colsOptions"
              :key="it.key">
              <span class="pri">{{ it.label }}</span>
              <p class="sub">{{ it.description }}</p>
            </el-checkbox>
          </el-checkbox-group>
          <div slot="foot">
            &nbsp;
          </div>
        </PpCard>
      </div>
    </div>
    <div class="list">
      <div class="list-title">
        Drag to rearrange the order
      </div>
    </div>
    <div class="list is-gapless">
      <div class="list-content list-fullwidth">
        <div class="el-table users--table el-table--border el-table--scrollable-x"
        style="width: 100%; overflow-x: auto;">
          <div class="">
            <table class="el-table__header" cellspacing="0" cellpadding="0" border="0">
              <thead>
                <tr>
                  <SlickList
                    axis="x"
                    :distance="2"
                    helperClass="dragMe"
                    v-model="selectedCols">
                    <SlickItem class="is-table-cell" v-for="(it, idx) in selectedCols"
                      :index="idx"
                      :key="it">
                      <th class="users--table-th grab-icon">
                        <div class="cell">
                          {{ it | colLable('label') }}
                          <a @click.prevent="doRemoveCol(idx)">
                            <i class="has-text-danger el-icon-circle-close is-size-6" />
                          </a>
                        </div>
                        <div class="fake-cell">...</div>
                        <div class="fake-cell">...</div>
                        <div class="fake-cell">...</div>
                      </th>
                      <!-- <el-tag
                        :disable-transitions="true"
                        :hit="true"
                        type="danger"
                        closable
                        @close="handleSelectedColsTagClosed(idx)">{{ it | colLable('label') }}</el-tag> -->
                    </SlickItem>
                  </SlickList>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <PpGap />
        <div class="list-label">
          * New column will be append to above sample table, scroll to the right to view it
        </div>
      </div>
    </div>
    <div class="list">
      <div class="list-content"></div>
      <div class="list-action">
        <el-button
          :disabled="!dirty"
          type="success"
          @click="doSaveConfiguredCols">SAVE</el-button>
      </div>
    </div>
    <div class="list is-narrow"></div>
  </article>
</template>

<script>
import { SlickList, SlickItem } from 'vue-slicksort'

import orderCols from '../../orderColumns'
import { mapGetters } from 'vuex'

export default {
  name: 'ListColsConfig',
  components: {
    SlickList,
    SlickItem
  },
  filters: {
    colLable (val, field = 'label') {
      return orderCols.findByKey(val)[field] || {}
    }
  },
  props: {
    cols: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      dirty: false,
      selectedCols: [],
      defaultSort: { order: 'descending', prop: '"order"."orderReference"' }

      // colsOptions: orderCols.list.sort((a, b) => (a.label < b.label ? -1 : 1))
    }
  },
  computed: {
    ...mapGetters(['mealInfoFeatureEnabled', 'rateTypeEnabled']),
    colsOptions () {
      let cols = orderCols.list.sort((a, b) => (a.label < b.label ? -1 : 1))
      if (!this.mealInfoFeatureEnabled) {
        cols = cols.filter(item => item.label !== 'Meal Voucher info')
      }
      if (!this.rateTypeEnabled) {
        cols = cols.filter(item => item.label !== 'Rate type')
      }
      return cols
    }
  },
  watch: {
    'selectedCols' (nV) {
      this.dirty = true
    }
  },
  created () {
    this.selectedCols = [...this.cols]
    this.$nextTick(() => {
      this.dirty = false
    })
  },
  methods: {
    doRemoveCol (idx) {
      this.selectedCols.splice(idx, 1)
    },
    doSaveConfiguredCols () {
      this.$emit('save', { cols: [ ...this.selectedCols ] })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .grouped-checkbox {
    margin-left: 0;
    margin-bottom: $bleed * 2;
    width: 240px;
    vertical-align: top;
    /deep/ .el-checkbox__input {
      vertical-align: top;
      margin-top: $bleed;
    }
    .pri {
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      width: 200px;
    }
    .sub {
      width: 200px;
      opacity: 0.68;
      font-size: $size-7;
      padding-right: $bleed * 3;
      white-space: initial;
      line-height: 1.2;
    }
  }
  .is-table-cell {
    display: table-cell;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
    .cell {
      word-break: keep-all;
      white-space: nowrap;
    }
  }
  .cols-wrapper {
    max-height: 433px;
    overflow: auto;
  }
  .dragMe {
    z-index: $_jh_fullscreen-z-index + 1;
    pointer-events: inherit !important;
    cursor: grabbing;
    display: flex;
    align-items: center;
    padding: $bleed * 2 0;
    color: $grey;
    border-style: solid;
    border-color: $link;
    border-width: 2px;
    font-size: $size-7;
    background-color: $white-bis;
    text-transform: capitalize;
    text-align: center;
    .cell {
    padding-left: 8px;
    padding-right: 8px;
    }
  }
  .fake-cell {
    display: block;
    background: white;
    text-align: center;
    border-top: 2px solid $grey-lighter;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 40px;
    & + .fake-cell {
      border-width: 1px;
    }
  }
</style>
