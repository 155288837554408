<template>
  <section class="section is-small">
    <div class="">
      <div class="list is-gapless">
        <div class="list-content">
          <h4 class="title is-4">Orders</h4>
        </div>
        <div class="list-action">
        </div>
      </div>
      <PpGap />
      <article class="box">
        <div class="form-content">
          <el-form
            inline
            size="medium"
            label-position="top"
            ref="localForm"
            :model="localForm">
            <el-form-item
              prop="appCode"
              label="Application"
              name="appCode">
              <el-select
                style="min-width: 250px;"
                v-model="localForm.applicationCodes"
                multiple
                filterable
                collapse-tags
                value-key="appCode"
                @change="debounceDoOrderQuery"
                placeholder="Any">
                <el-option
                  v-for="it in apps"
                  :key="it.appCode"
                  :label="it.label"
                  :value="it.appCode">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="Requested at range (YYYY-MM-DD)">
              <el-date-picker
                style="width: 270px;"
                v-model="localForm.requestedAtRange"
                type="daterange"
                align="left"
                start-placeholder="From"
                end-placeholder="To"
                @change="debounceDoOrderQuery"
                :picker-options="requestedRangeOptions">
                <i class="el-icon-back is-rotate-180" slot="range-separator" />
              </el-date-picker>
            </el-form-item>
            <el-form-item
              prop="orderStatus"
              label="Order status"
              name="orderStatus">
              <el-select
                style="min-width: 250px;"
                v-model="localForm.orderStatuses"
                multiple
                collapse-tags
                value-key="code"
                @change="debounceDoOrderQuery"
                placeholder="Any">
                <el-option
                  v-for="it in orderStatusesOptions"
                  :key="it.code"
                  :label="it.label"
                  :value="it.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              prop="orderReference"
              style="width: 160px;"
              label="Order reference"
              name="orderReference"
              :rules="[{ type: 'string', min: 4, message: 'Type at least 4 chracters to search.', trigger: [ 'change', 'input' ] }]">
              <el-input
                clearable
                v-model.lazy.trim="localForm.orderReference"
                @input="debounceDoOrderQuery"
                placeholder="E.g: ABCDXXXXXXX"></el-input>
            </el-form-item>
            <el-form-item
              prop="stayPeriods"
              label="Stay periods (YYYY-MM-DD)"
              name="stayPeriod"
              :rules="[
                { type: 'string', pattern: /^\d{4}-[0,1]{1}\d{1}-[0,1,2,3]{1}\d{1}$/, message: 'Invalid date format (YYYY-MM-DD)', trigger: [ 'change', 'input' ] }
              ]">
              <el-date-picker
                v-model="localForm.stayPeriods"
                value-format="yyyy-MM-dd"
                type="date"
                @input="debounceDoOrderQuery"
                placeholder="YYYY-MM-DD">
              </el-date-picker>
              <!-- <el-input
                clearable
                v-model.lazy.trim="localForm.stayPeriods"
                placeholder="E.g: 2020-02-20"></el-input> -->
              <p class="form-help-text">Either exact check-in/check-out date or date in between</p>
            </el-form-item>
            <el-form-item
              prop="orderPerson"
              style="min-width: 260px;"
              label="Primary guest/Contact person"
              name="orderPerson"
              :rules="[{ type: 'string', min: 2, message: 'Type at least 2 chracters to search.', trigger: [ 'change', 'input' ] }]">
              <el-input
                clearable
                v-model.lazy.trim="localForm.orderPerson"
                name="orderPerson"
                @input="debounceDoOrderQuery"
                placeholder="E.g: James bond"></el-input>
              <p class="form-help-text">Any of the guest or contact person's name</p>
            </el-form-item>
            <el-form-item
              v-if="isClearFormNeeded"
              style="width: 80px">
              <template slot="label">
                &nbsp;
              </template>
              <el-button
                size="medium"
                icon="el-icon-close"
                @click="doClearAllFields"
                type="danger">
                  Clear All Filters
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </article>
      <transition name="view-slide-down">
      <article
        v-if="showColumnConfig"
        class="box fullscreen form-content">
        <div class="list is-gapless">
          <div class="list-content">
            <h4 class="title is-4">Column configurations</h4>
          </div>
          <div class="list-action">
            <a @click.prevent="showColumnConfig = false">
              <i class="el-icon-close is-size-3" />
            </a>
          </div>
        </div>
        <PpGap />
        <ListColsConfig :cols="cols" @save="doSaveConfiguredCols"/>
      </article>
      </transition>
      <!-- <el-select
        multiple
        @change="handleColsChanged"
        v-model="selectedCols">
        <el-option
          v-for="it in colsOptions"
          :label="it.label"
          :value="it.key"
          :key="it.key"></el-option>
      </el-select> -->
      <article class="box">
      <div class="list is-topless is-bottomless has-border-bottom">
        <div class="list-content">
          <span v-if="isLoading"><i class="el-icon-loading" /></span>
          <span v-else>Showing <span class="has-text-weight-semibold">{{ summary.showing[0] }}</span> – <span class="has-text-weight-semibold">{{ summary.showing[1] }}</span> of <span class="has-text-weight-semibold">{{ summary.total | number }}</span> orders</span>
        </div>
        <div class="list-action">
          <el-tooltip
            placement="top"
            :content="excelTooltip">
          <el-button
            @click="doDownloadAsXls"
            size="text"
            type="primary">Download as excel</el-button>
          </el-tooltip>
          <el-button v-if="checkPermission(['ORDERS_CONFIGURE_TABLE'])"
                     @click="showColumnConfig = true"
                     size="text"
                     type="primary">Configure columns
          </el-button>
        </div>
      </div>
      <el-table
        class="users--table"
        ref="usersTable"
        :data="rows"
        empty-text="No orders"
        border

        highlight-current-row
        header-cell-class-name="users--table-th"
        style="width: 100%; min-height: 350px;"
        row-key="orderReference"
        @row-click="handleRowClicked"
        @current-change="handleCurrentChanged"
        v-loading="isLoading"
        :defaultSort="defaultSort"
        @sort-change="handleSortChanged">
        <!-- <el-table-column
          type="index"
          label="#"
          width="40"
          align="center">
        </el-table-column> -->
        <ListColsLoader v-if="regeneratedTable" :cols="cols" />
      </el-table>
      <div class="list">
        <div class="list-content"></div>
        <div class="list-action">
          <el-pagination
            @size-change="doChangeTableSize"
            @current-change="debounceDoOrderQuery()"
            :current-page.sync="localForm.page"
            :page-sizes="[10, 25, 60, 100, 300]"
            :page-size.sync="localForm.limit"
            layout="sizes, prev, pager, next"
            :total="summary.total">
          </el-pagination>
        </div>
      </div>
      </article>
      <PpGap size="large" />
    </div>
    <router-view />
    <transition name="view--slideup">
    </transition>
    <!-- <PpOverlay /> -->
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { ordersList, ordersXls, applicationsList } from '../../factories/manage'
import dates from '../../plugins/dates'
import { toArray as orderStatusesToArray } from '../../plugins/orderStatuses'
import store from '../../store'

import ListColsLoader from './ListColsLoader'
import ListColsConfig from './ListColsConfig'
import orderCols from '../../orderColumns'
import checkPermission from '@/plugins/permission'

store.dispatch('loadOrdersCols')

const ORDERS_TABLE_SIZE_KEY = 'lv2prt_tabsuze'

const tableSize = {
  get () {
    let k
    try {
      k = JSON.parse(localStorage.getItem(ORDERS_TABLE_SIZE_KEY))
    } catch (err) {
      console.error('No table size value found')
    }
    if (k == null) {
      this.set(10)
      k = 10
    }
    return k
  },
  set (val) {
    localStorage.setItem(ORDERS_TABLE_SIZE_KEY, JSON.stringify(val))
  }
}

export default {
  name: 'OrdersList',
  components: {
    ListColsConfig,
    ListColsLoader
  },
  filters: {
    colLable (val, field = 'label') {
      return orderCols.findByKey(val)[field] || {}
    }
  },
  props: {
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      showColumnConfig: false,
      regeneratedTable: true,
      isLoading: false,
      isFetchDone: true,
      defaultSort: { order: 'descending', prop: '"order"."orderReference"' },

      localForm: {
        applicationCodes: [],
        orderStatuses: [],
        stayPeriods: '',
        orderPerson: '',
        orderReference: '',
        sort: '',
        limit: undefined,
        page: 1
      },

      debounced: {
        doOrderQuery: undefined
      },

      currentRow: '',
      colsOptions: orderCols.list,
      orderStatusesOptions: orderStatusesToArray(),
      requestedRangeOptions: {
        shortcuts: [{
          text: 'Today',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last week',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last month',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last 3 months',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  computed: {
    ...mapState({
      rows: state => state.orders.rows,
      summary: state => state.orders.summary,
      cols: state => state.orders.cols,
      apps: state => state.apps.list
    }),
    ...mapGetters(['isUserConfigureColumnsEnabled', 'mealInfoFeatureEnabled', 'rateTypeEnabled']),
    excelTooltip () {
      return `Export all ${this.summary.total} orders (filters might applied)`
    },
    isClearFormNeeded () {
      return this.localForm.stayPeriods || this.localForm.orderPerson || this.localForm.orderReference || this.localForm.requestedAtRange || this.localForm.orderStatuses.length > 0 || this.localForm.applicationCodes.length > 0
    }
  },
  created () {
    this.localForm.limit = tableSize.get()
    applicationsList()
  },
  mounted () {
    this.localForm = {
      ...this.localForm,
      ...this.query,
      /**
       * Prase the orderStatuses to {code, label}
       */
      ...(this.query.orderStatuses != null ? {
        orderStatuses: this.query.orderStatuses.split(',').filter(e => e)
      } : undefined),
      ...(this.query.applicationCodes != null ? {
        applicationCodes: this.query.applicationCodes.split(',').filter(e => e)
      } : undefined),
      /**
       * Prase the requestedAtRange to string[]
       * Example:
       * 2018-12-05T08:48:42.312Z|2018-12-06T08:48:42.312Z -> ['2018-12-05T08:48:42.312Z', '2018-12-06T08:48:42.312Z']
       */
      ...(this.query.requestedAtRange ? { requestedAtRange: this.query.requestedAtRange.split('|') } : undefined),
      ...(this.query.limit ? { limit: Number(this.query.limit) } : undefined),
      ...(this.query.page ? { page: Number(this.query.page) } : undefined)
    }
    if (this.isUserConfigureColumnsEnabled) {
      this.doOrderQuery(true, this.cols)
    } else {
      let defaultCols = ['requestedAt', 'orderStatus', 'orderReference', 'stayPeriods', 'property', 'roomDescription', 'roomCount', 'adultCount', 'contactPerson', 'primaryGuest', 'totalChargedAmount', 'glCode', 'children', 'remarks']
      if (this.mealInfoFeatureEnabled) {
        defaultCols.splice(-1, 1)
        defaultCols.splice(2, 0, 'mealVoucher', 'remarks')
      }
      // WL-1237 - added only for Fly Louie app admin
      if (this.rateTypeEnabled) {
        defaultCols.splice(5, 0, 'rateType')
      }
      store.dispatch('setOrdersCols', { cols: defaultCols }).then(() => {
        this.doOrderQuery(true, this.cols)
      })
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.isFetchDone = false
    // WL-337
    if (to.name === 'orderDetails') {
      next()
      return
    }
    ordersList({
      limit: this.localForm.limit,
      page: 1,
      ...to.query
    }, this.cols)
      .then(() => {
        this.isFetchDone = true
        next()
      })
      .catch(() => next())
  },
  watch: {
    'cols': {
      immediate: true,
      handler (nV) {
        this.regeneratedTable = false
        this.$nextTick(() => (this.regeneratedTable = true))
      }
    }
  },
  methods: {
    checkPermission,
    doResetToPageOne () {
      this.localForm.page = 1
    },
    handleCurrentChanged (val) {
      this.currentRow = val
    },
    handleRowClicked (row, column, event) {
      this.$refs.usersTable.setCurrentRow(row)
      event.stopPropagation()
      if (this.isFetchDone) this.$router.push({ name: 'orderDetails', params: { orderReference: row.order.orderReference } })
    },
    handleSortChanged ({ column, prop, order }) {
      const orderType = {
        ascending: 'ASC',
        descending: 'DESC'
      }
      this.localForm.sort = prop == null ? '' : `${prop},${orderType[order] ? orderType[order] : 'ASC'}`
      this.debounceDoOrderQuery(true)
    },
    handleSelectedColsTagClosed (idx) {
      this.selectedCols.splice(idx, 1)
    },
    handleColsChanged () {
      ordersList(this.query, this.selectedCols)
    },
    doChangeTableSize (limit) {
      this.localForm.page = 1 // <- reset to page 1
      tableSize.set(limit)
      this.debounceDoOrderQuery()
    },
    doDownloadAsXls () {
      this.doOrderQuery(true, this.cols, 'xls')
    },
    debounceDoOrderQuery (resetToPageOne) {
      clearTimeout(this.debounced.doOrderQuery)
      if (resetToPageOne) {
        this.doResetToPageOne()
      }
      this.debounced.doOrderQuery = setTimeout(() => this.doOrderQuery(), 300)
    },
    doSaveConfiguredCols (props) {
      this.showColumnConfig = false
      this.isLoading = true
      /**
       * Delay the execution for user experince purpose
       */
      setTimeout(() => {
        this.doOrderQuery(true, props.cols)
          .then(() => store.dispatch('setOrdersCols', props))
          .then(() => (this.isLoading = false))
      }, 500)
    },
    doClearAllFields () {
      this.localForm = {
        ...this.localForm,
        orderStatuses: [],
        applicationCodes: [],
        stayPeriods: '',
        orderPerson: '',
        orderReference: '',
        requestedAtRange: undefined,
        page: 1
      }
      this.doOrderQuery()
    },
    /**
     * @param dispatch {boolean} - weather to search without updating the url
     * @param cols {string[]} - Requested cols
     * @param type {xls|csv|json} - request type, we will send to seperate endpoint.
     */
    doOrderQuery (dispatch, cols, type) {
      return this.$refs.localForm.validate()
        .then(async (valid) => {
          if (valid) {
            const sanitize = {
              /**
               * Verbal conditioning here are inentionaly.
               * The url params sometimes are empty string. E.g: xxxx/orders?requestedAtRange=
               * It will result unforeseen condition in NodeJs or VueJs
               */
              ...(this.localForm.stayPeriods ? { stayPeriods: this.localForm.stayPeriods } : undefined),
              ...(this.localForm.orderPerson ? { orderPerson: this.localForm.orderPerson } : undefined),
              ...(this.localForm.orderReference ? { orderReference: this.localForm.orderReference } : undefined),
              ...(this.localForm.requestedAtRange
                ? {
                  requestedAtRange: dates.fullDayRange(this.localForm.requestedAtRange).map(e => e.toJSON()).join('|')
                } : undefined),
              ...(Array.isArray(this.localForm.orderStatuses) && this.localForm.orderStatuses.length > 0
                ? {
                  orderStatuses: this.localForm.orderStatuses.join(',')
                } : undefined),
              ...(Array.isArray(this.localForm.applicationCodes) && this.localForm.applicationCodes.length > 0
                ? {
                  applicationCodes: this.localForm.applicationCodes.join(',')
                } : undefined),
              ...(this.localForm.sort ? { sort: this.localForm.sort } : undefined),
              ...(this.localForm.limit ? { limit: this.localForm.limit } : undefined),
              ...(this.localForm.page ? { page: this.localForm.page } : undefined)
            }
            /**
             * Skip router push
             */
            if (dispatch) {
              if (type === 'xls') {
                this.isLoading = true
                await ordersXls(sanitize, cols, this.summary.total)
                this.isLoading = false
              }
              return ordersList(sanitize, cols)
                .then(() => {
                  if (this.$refs.localForm) this.$refs.localForm.clearValidate()
                })
            } else {
              this.isLoading = true
              this.$router.replace({ query: sanitize }, () => {
                this.$refs.localForm.clearValidate()
                this.isLoading = false
              }, (err) => {
                console.error('Route change failure', err)
                this.isLoading = false
              })
            }
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .users--table /deep/ tr {
    cursor: pointer !important;
  }

  @import '../../styles/bulma-variables';
</style>
<style lang="scss">
  .fullscreen .box {
    overflow-y: auto;
  }
</style>
