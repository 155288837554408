<template>
  <PpModal v-model="open">
    <PpCard class="box has-border is-flex">
      <div class="send-email-modal-content">
        <el-form
          v-if="!isEmailSent"
          ref="sendEmailForm"
          :model="sendEmailForm"
          :rules="rules"
          label-position="top"
          status-icon
          class=""
          @submit.native.prevent>
          <div>
            <h3
              v-if="status === 'CONFIRMED'"
              class="is-size-4 has-text-secondary has-text-weight-bold">Send Confirmation email + voucher</h3>
            <h3
              v-if="status === 'CANCELLED'"
              class="is-size-4 has-text-secondary has-text-weight-bold">Send Cancellation Email</h3>
            <h3
              v-if="status === 'FAILURE'"
              class="is-size-4 has-text-secondary has-text-weight-bold">Send Failure Email</h3>
          </div>
          <el-form-item
            label="Email"
            prop="email">
            <el-input
              v-model.trim="sendEmailForm.email"
              placeholder="e.g. yourname@company.com"
              type="email"
              autocomplete="email"/>
          </el-form-item>
          <div class="has-text-right">
            <el-button
              :disabled="isFetching"
              style="min-width: 115px;"
              @click="handleCancel">
              Cancel
            </el-button>
            <el-button
              type="primary"
              :loading="isFetching"
              style="min-width: 125px;"
              native-type="button"
              @click="handleSendEmail">
              Send
            </el-button>
          </div>
        </el-form>
        <div
          v-else
          class="">
          <div class="">
            <h3 class="is-size-4 has-text-secondary has-text-weight-bold">Email sent</h3>
          </div>
          <div class="">
            <p v-if="status === 'CONFIRMED'">The confirmation email + voucher has been sent to <b>{{ sendEmailForm.email }}</b></p>
            <p v-if="status === 'CANCELLED'">The cancellation email has been sent to <b>{{ sendEmailForm.email }}</b></p>
            <p v-if="status === 'FAILURE'">The failure email has been sent to <b>{{ sendEmailForm.email }}</b></p>
          </div>
          <div class="column is-12 has-text-right">
            <el-button
              type="primary"
              style="min-width: 125px;"
              @click="handleClose">
              OK
            </el-button>
          </div>
        </div>
      </div>
    </PpCard>
  </PpModal>
</template>

<script>
import lv2, { axiosErrorHandler } from '../../factories/lv2Api'

const asyncPostSendEmail = async (payload) => {
  try {
    const { data } = await lv2.post('/sendEmail', payload)
    // eslint-disable-next-line no-unused-vars
    const { outlets, meta } = data
    return outlets
  } catch (err) {
    const parseErr = axiosErrorHandler(err)
    throw parseErr
  }
}
export default {
  name: 'SendEmailModal',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    orderReference: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sendEmailForm: {
        email: this.email
      },
      isEmailSent: false,
      rules: {
        email: [
          {
            type: 'email',
            message: 'Invalid email format.',
            trigger: [
              'change', 'blur', 'input'
            ]
          }, {
            required: true,
            message: 'Email is required.',
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      },
      isFetching: false
    }
  },
  watch: {
    email (nV) {
      this.sendEmailForm.email = this.email
    }
  },
  computed: {
    status () {
      return this.order.status && this.order.status.code
    }
  },
  methods: {
    handleSendEmail (e) {
      e.preventDefault()
      this.$refs.sendEmailForm.validate(async (valid) => {
        if (valid) {
          this.isFetching = true
          asyncPostSendEmail({
            orderReference: this.orderReference,
            appCode: this.order.appCode,
            globalPreview: this.order.previewMode,
            email: this.sendEmailForm.email
          }).then(res => {
            this.isFetching = false
            this.isEmailSent = true
            setTimeout(() => {
              this.$emit('sent')
            }, 5000)
          }).catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            })
            this.isFetching = false
            this.isEmailSent = false
          })
          return true
        } else {
          return false
        }
      })
    },
    handleClose () {
      this.isEmailSent = false
      this.$emit('update:open', false)
    },
    handleCancel () {
      this.isEmailSent = false
      this.$refs.sendEmailForm.resetFields()
      this.handleClose()
    }
  }
}
</script>

<style scoped>

</style>
